import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
// THIRD PARTY  COMPONENTS
import { Link } from 'react-router-dom';

// API AND VARIABLES
import { globalSearch } from 'src/@core/api/globalsearch';

// STYLES
import { bgBlur } from 'src/theme/css';
import '../../pages/dashboard/style.css';
import { useTheme } from '@mui/material/styles';

// @MUI
import { Box } from '@mui/system';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import {
  Tab,
  Card,
  Grid,
  Tabs,
  TextField,
  Typography,
  CardContent,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// HOOKS
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { useBoolean } from 'src/hooks/use-boolean';
// COMPONENTS
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';

// CUSTOM FUNCTIOJNS
import { handleToast } from 'src/utils/toast';
import { isUserLoggedIn } from 'src/utils/utils';

// OTHER
import DealerDetailsModal from 'src/pages/dashboard/dealerDetailsModal';
import { HEADER, NAV } from '../config-layout';
import { AccountPopover, NotificationsPopover } from '../_common';

// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const userData = isUserLoggedIn();
  const settings = useSettingsContext();
  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;
  const dialog = useBoolean();

  const [loanData, setLoanData] = useState([]);
  const [dataLoad, setDataLoad] = useState(false);
  const [searched, setSearched] = useState(false);
  const [dealerData, setDealerData] = useState([]);
  const [loanCounter, setLoanCounter] = useState(0);
  const [openSearch, setOpenSearch] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [searchCall, setSearchCall] = useState(false);
  const [dealerCounter, setDealerCounter] = useState(0);
  const [insuranceData, setInsuranceData] = useState([]);
  const [insuranceCounter, setInsuranceCounter] = useState(0);
  const [searchValue, setSearchValue] = useState({ search_value: '' });

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const handleSearch = async () => {
    if (searchValue.search_value.trim().length > 0 && searchCall === true) {
      setInsuranceCounter(0);
      setDealerCounter(0);
      setLoanCounter(0);
      setDataLoad(true);

      await globalSearch(searchValue)
        .then((res) => {
          filterData(res.data);
          setSearched(true);
          setDataLoad(false);
          setSearchCall(false);
        })
        .catch((error) => {
          setDataLoad(false);
          setSearchCall(false);
          handleToast(0, error?.response?.data?.description);
        });
    }
  };

  const filterData = (data) => {
    if (data && data?.length > 0) {
      data.forEach((item) => {
        if (item.loanData) {
          setLoanData(item.loanData);
          setLoanCounter(item.counter);
        }
        if (item.dealerData) {
          setDealerData(item.dealerData);
          setDealerCounter(item.counter);
        }
        if (item.insuranceData) {
          setInsuranceData(item.insuranceData);
          setInsuranceCounter(item.counter);
        }
      });
    }
  };

  const TABS = [
    {
      label: 'Loan',
      counter: loanCounter,
    },
    {
      label: 'Insurance',
      counter: insuranceCounter,
    },
    {
      label: 'Dealer',
      counter: dealerCounter,
    },
  ];

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        <div className="container">
          <Link className="content" to="/contestview">
            {/* <div style={{ background: '#CC843A', borderRadius: '100px', height: '6px' }} /> */}
            <div style={{ marginRight: '4px', marginLeft: '6px' }}>Live Contests </div>
            <div style={{ background: '#cc843a', padding: '8px', borderRadius: '50px' }}>
              <img
                style={{ zIndex: '2', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                className="trophy-icon"
                src="/assets/icons/navbar/noto--trophy.svg"
                alt="imag"
                height="25"
                width="25"
              />
            </div>
          </Link>
        </div>
        {openSearch && (
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            sx={{
              position: 'fixed',
              top: 10,
              left: 0,
              right: 0,
              zIndex: 1300,
              backgroundColor: 'background.paper',

              padding: 1,
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchValue.search_value}
              onChange={(e) => {
                setSearchValue({ search_value: e.target.value });
                setSearchCall(true);
              }}
            />

            <Stack position="end" spacing={1} flexGrow={1} direction="row" alignItems="center">
              <LoadingButton
                onClick={handleSearch}
                loading={dataLoad}
                color="primary"
                type="submit"
                variant="outlined"
                size="small"
                sx={{ width: '45px', p: 1, minHeight: 'unset', minWidth: 'unset' }}
              >
                <Iconify icon="iconamoon:search" />
              </LoadingButton>
              <LoadingButton
                color="error"
                type="submit"
                variant="outlined"
                onClick={() => {
                  setOpenSearch(false);
                  setSearchValue({ search_value: '' });
                  setSearched(false);
                  setCurrentTab(0);
                  setSearchCall(false);
                }}
                size="small"
                sx={{ width: '45px', p: 1, minHeight: 'unset', minWidth: 'unset' }}
              >
                <Iconify icon="tabler:x" />
              </LoadingButton>
            </Stack>

            {searched && (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    position: 'fixed',
                    top: 55,
                    left: 0,
                    right: 0,
                    zIndex: 1300,
                    backgroundColor: 'background.paper',

                    p: 0.5,
                  }}
                >
                  <Tabs value={currentTab} onChange={handleChangeTab}>
                    {TABS.slice(0, 3).map((tab) => (
                      <Tab
                        key={tab.value}
                        iconPosition="end"
                        value={tab.value}
                        label={tab.label}
                        icon={
                          <Label
                            variant={
                              ((tab.value === 'all' || tab.value === currentTab) && 'filled') ||
                              'soft'
                            }
                            color={
                              (tab.value === 'unread' && 'info') ||
                              (tab.value === 'archived' && 'success') ||
                              'default'
                            }
                          >
                            {tab.counter}
                          </Label>
                        }
                        sx={{
                          '&:not(:last-of-type)': {
                            mr: 3,
                          },
                        }}
                      />
                    ))}
                  </Tabs>
                </Stack>

                {searched && (
                  <>
                    {dataLoad === true ? (
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                          position: 'fixed',
                          top: 90,
                          left: 0,
                          right: 0,
                          zIndex: 1300,

                          backgroundColor: 'background.paper',
                          padding: 2,
                        }}
                      >
                        <Box
                          sx={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            top: 130,
                            right: 0,
                            zIndex: 1300,
                            backgroundColor: 'background.paper',

                            height: '300px',
                            overflowY: 'auto',
                            padding: 1,

                            mb: 1,
                          }}
                        >
                          <Stack
                            spacing={2}
                            style={{ marginBottom: '10px' }}
                            sx={{
                              height: '100%',
                              marginBottom: 2,
                            }}
                          >
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                marginBottom: 2,
                              }}
                            >
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                height="100%"
                                margin="auto"
                                textAlign="center"
                                marginTop="80px"
                              >
                                <CircularProgress />
                              </Box>
                            </Grid>
                          </Stack>
                        </Box>
                      </Stack>
                    ) : (
                      <Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{
                            position: 'fixed',
                            top: 130,
                            left: 0,
                            right: 0,
                            zIndex: 1300,
                            backgroundColor: 'background.paper',
                            padding: 2,
                          }}
                        >
                          <Stack>
                            <div>
                              {currentTab === 0 ? (
                                <div>
                                  <Box
                                    sx={{
                                      position: 'fixed',
                                      bottom: 0,
                                      left: 0,
                                      top: 110,
                                      right: 0,
                                      zIndex: 1300,
                                      backgroundColor: 'background.paper',

                                      height: '300px',
                                      overflowY: 'auto',
                                      padding: 1,

                                      mb: 1,
                                    }}
                                    style={{ boxShadow: '0 2px 3px -3px black' }}
                                  >
                                    <Stack
                                      spacing={2}
                                      style={{ marginBottom: '10px' }}
                                      sx={{
                                        height: '100%',
                                        marginBottom: 2,
                                      }}
                                    >
                                      <Grid
                                        container
                                        spacing={2}
                                        sx={{
                                          marginBottom: 2,
                                        }}
                                      >
                                        {loanData.length > 0 ? (
                                          loanData.map((item, index) => (
                                            <Grid item xs={12} sm={6} md={4} key={index}>
                                              <Card key={index} sx={{ boxShadow: 3 }}>
                                                <CardContent>
                                                  <Typography
                                                    variant="h6"
                                                    sx={{
                                                      justifyContent: 'center',
                                                      textAlign: 'center',
                                                    }}
                                                  >
                                                    {item.dealer_name}
                                                  </Typography>
                                                  <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                  >
                                                    <b>Customer Name:</b> {item.customer_name}
                                                  </Typography>
                                                  <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                  >
                                                    <b>Approved Date: </b>
                                                    {item.approved_date}
                                                  </Typography>
                                                  <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                  >
                                                    <b>Customer Phone:</b> {item.customer_mono}
                                                  </Typography>
                                                  <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                  >
                                                    <b>Vehicle Name: </b> {item.vehicle_name}
                                                  </Typography>
                                                  <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                  >
                                                    <b>Vehicle No: </b> {item.vehicle_no}
                                                  </Typography>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          ))
                                        ) : (
                                          <Stack
                                            sx={{
                                              justifyContent: 'center',
                                              margin: 'auto',
                                              marginTop: '100px',
                                            }}
                                          >
                                            No Data Available
                                          </Stack>
                                        )}
                                      </Grid>
                                    </Stack>
                                  </Box>
                                </div>
                              ) : null}
                              {currentTab === 1 ? (
                                <div>
                                  <Box
                                    sx={{
                                      position: 'fixed',
                                      bottom: 0,
                                      left: 0,
                                      top: 110,
                                      right: 0,
                                      zIndex: 1300,
                                      backgroundColor: 'background.paper',

                                      height: '300px',
                                      overflowY: 'auto',
                                      padding: 1,

                                      mb: 1,
                                    }}
                                    style={{ boxShadow: '0 2px 3px -3px black' }}
                                  >
                                    <Stack
                                      spacing={2}
                                      style={{ marginBottom: '10px' }}
                                      sx={{
                                        height: '100%',
                                        marginBottom: 1,
                                      }}
                                    >
                                      <Grid
                                        container
                                        spacing={2}
                                        sx={{
                                          marginBottom: 1,
                                        }}
                                      >
                                        {insuranceData.length > 0 ? (
                                          insuranceData?.map((item, index) => (
                                            <Grid item xs={12} sm={6} md={4} key={index}>
                                              <Card key={index} sx={{ boxShadow: 3 }}>
                                                <CardContent>
                                                  <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                  >
                                                    <b>Dealer Name: </b>
                                                    {item.dealer_name}
                                                  </Typography>
                                                  <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                  >
                                                    <b>Commission</b> {item.commission}
                                                  </Typography>
                                                  <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                  >
                                                    <b>Premium Price</b> {item.premium_price}
                                                  </Typography>
                                                  <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                  >
                                                    <b>Vehicle Name:</b> {item.vehicle_name}
                                                  </Typography>
                                                  <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                  >
                                                    <b>Vehicle No: </b>
                                                    {item.vehicle_no}
                                                  </Typography>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          ))
                                        ) : (
                                          <Stack
                                            sx={{
                                              justifyContent: 'center',
                                              margin: 'auto',
                                              marginTop: '100px',
                                            }}
                                          >
                                            No data Available
                                          </Stack>
                                        )}
                                      </Grid>
                                    </Stack>
                                  </Box>
                                </div>
                              ) : null}
                              {currentTab === 2 ? (
                                <div>
                                  <Box
                                    sx={{
                                      position: 'fixed',
                                      bottom: 0,
                                      left: 0,
                                      top: 110,
                                      right: 0,
                                      zIndex: 1300,
                                      backgroundColor: 'background.paper',
                                      height: '300px',
                                      overflowY: 'auto',
                                      padding: 1,

                                      mb: 1,
                                    }}
                                    style={{ boxShadow: '0 2px 3px -3px black' }}
                                  >
                                    <Stack
                                      spacing={2}
                                      style={{ marginBottom: '10px' }}
                                      sx={{
                                        height: '100%',
                                        marginBottom: 1,
                                      }}
                                    >
                                      <Grid
                                        container
                                        spacing={2}
                                        sx={{
                                          marginBottom: 1,
                                        }}
                                      >
                                        {dealerData.length > 0 ? (
                                          dealerData?.map((item, index) => (
                                            <Grid item xs={12} sm={6} md={4} key={index}>
                                              <Card key={index} sx={{ boxShadow: 3 }}>
                                                <CardContent>
                                                  <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                  >
                                                    <b>Name: </b>
                                                    {item.first_name + item.last_name}
                                                  </Typography>
                                                  <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                  >
                                                    <b>Email:</b> {item.email}
                                                  </Typography>
                                                  <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                  >
                                                    <b>Phone: </b> {item.phoneno}
                                                  </Typography>
                                                  <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                  >
                                                    <b>Status: </b>
                                                    {item.status === 1 ? 'Active' : 'Deactive'}
                                                  </Typography>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          ))
                                        ) : (
                                          <Stack
                                            sx={{
                                              justifyContent: 'center',
                                              margin: 'auto',
                                              marginTop: '100px',
                                            }}
                                          >
                                            No data Available
                                          </Stack>
                                        )}
                                      </Grid>
                                    </Stack>
                                  </Box>
                                </div>
                              ) : null}
                            </div>
                          </Stack>
                        </Stack>
                      </Stack>
                    )}
                  </>
                )}
              </>
            )}
          </Stack>
        )}
        {userData?.u_type === 1 && (
          <IconButton variant="contained" onClick={() => setOpenSearch(true)}>
            <Iconify icon="bitcoin-icons:search-filled" width={25} />
          </IconButton>
        )}

        {userData?.u_type === 0 && (
          <IconButton
            whileTap="tap"
            whileHover="hover"
            color="default"
            onClick={dialog.onTrue}
            title="View Detail"
          >
            <Iconify icon="entypo:popup" width={35} />
          </IconButton>
        )}

        {userData?.u_type === 0 && <NotificationsPopover />}
        <AccountPopover />
        <DealerDetailsModal dialog={dialog} dealerId={userData.user_id} />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
