
import Request from '.';
import { ApiRoutes } from '../constants';

export const getInsuranceApi = async (formdata) => {
  try {
    const res = await Request.post(ApiRoutes.GETINSURANCE,formdata);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getDealerListApi = async () => {
    try {
      const res = await Request.get(ApiRoutes.GETDEALERLIST);
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const insertInsuranceApi = async (formdata) => {
  try {

    const res = await Request.post(ApiRoutes.INSERTINSURANCE, formdata);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateInsuranceApi = async (formdata) => {
  try {
    const res = await Request.post(ApiRoutes.UPDATEINSURANCE, formdata);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteInsuranceApi = async (formdata) => {
  try {
    const res = await Request.get(ApiRoutes.DELETEINSURANCE + formdata);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const countInsuranceCommissionApi = async (formdata) => {
  try {
    const res = await Request.post(ApiRoutes.TOTALINSURANCECOMMISSION , formdata);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const downloadInsuranceReportApi = async (formdata) => {
  try {
    const res = await Request.post(ApiRoutes.DOWNLOADDEALERWISEINSURANCEREPORT , formdata);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

