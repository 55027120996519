/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-new */

import { useState, useEffect } from 'react';

// api & variables
import { getNotificationApi, readNotificationApi } from 'src/@core/api/notification';
import { ApiRoutes } from 'src/@core/constants';

// custom hooks
import { useBoolean } from 'src/hooks/use-boolean';

// custom components
import { varHover } from 'src/components/animate';
import CustomPopover from 'src/components/custom-popover/custom-popover';
import Iconify from 'src/components/iconify';

// functions
import { isUserLoggedIn } from 'src/utils/utils';
import { handleToast } from 'src/utils/toast';

// mui
import { m } from 'framer-motion';
import { LoadingButton } from '@mui/lab';
import { IconButton, Badge, Typography, Divider, Stack, Box, Button } from '@mui/material';
import moment from 'moment';

export default function NotificationsPopover() {
  const user = isUserLoggedIn();
  const drawer = useBoolean();
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [readLoading, setReadLoading] = useState(false);
  const [wsSocket, setWsSocket] = useState(null);

  useEffect(() => {
    if (Notification.permission === 'default') Notification.requestPermission();
  }, [Notification.permission]);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    await getNotificationApi()
      .then((res) => {
        setNotifications(res.data.rows);
        setNotificationCount(res.data.count);
      })
      .catch((err) => handleToast(0, err?.result?.data?.description));
  };

  const handleReadNotification = async (id) => {
    setReadLoading(true);
    const alldata = { n_id: [id] };
    await readNotificationApi(alldata)
      .then((res) => {
        fetchNotifications();
        setReadLoading(false);
      })
      .catch((err) => {
        handleToast(0, err?.result?.data?.description);
        setReadLoading(false);
      });
  };

  // Handle reading all notifications
  const handleReadAllNotification = async () => {
    await readNotificationApi()
      .then((res) => {
        fetchNotifications();
        setPopoverAnchorEl(null);
        handleToast(res?.data, res?.description);
      })
      .catch((err) => {
        handleToast(0, err?.res?.data?.description);
        setPopoverAnchorEl(null);
      });
  };

  // websocket function
  const WebSocketConnection = () => {
    const socket = new WebSocket(`${ApiRoutes.WEBSOCKET_URL}/${user.user_id}`);
    setWsSocket(socket);
    socket.onopen = () => {};
    socket.addEventListener('close', () => {
      WebSocketConnection();
    });
  };
  if (wsSocket === null) WebSocketConnection();

  if (wsSocket !== null && wsSocket.readyState === 1) {
    wsSocket.onmessage = (msg) => {
      fetchNotifications();
      const parsedMessage = JSON.parse(msg.data);
      if ('Notification' in window && parsedMessage?.msg?.key) {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            const notificationSend = new Notification(`${parsedMessage.msg.data.type}`, {
              body: parsedMessage.msg.data.msg,
            });
          }
        });
      }
    };
  }

  const handleNotificationClick = (event) => setPopoverAnchorEl(event.currentTarget);

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        sx={{
          width: 50,
          height: 50,
        }}
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={handleNotificationClick}
      >
        <Badge badgeContent={notificationCount} color="primary" showZero>
          <Iconify icon="solar:bell-bing-bold-duotone" width={32} />
        </Badge>
      </IconButton>

      <CustomPopover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={() => setPopoverAnchorEl(null)}
        sx={{ width: 300, maxHeight: 400 }}
      >
        <Stack spacing={1} sx={{ p: 1 }}>
          <Box
            sx={{ maxHeight: 300 }}
            style={{
              '-ms-overflow-style': 'none',
              scrollbarWidth: 'none',
              '-webkit-overflow-scrolling': 'touch',
              overflowY: 'auto',
            }}
          >
            <Typography
              variant="body2"
              color="text.secondary"
              style={{ paddingBottom: '8px' }}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Notification
              <Badge
                badgeContent={notificationCount}
                color="primary"
                showZero
                variant="outlined"
                size="small"
                sx={{ marginLeft: '60%' }}
              />
            </Typography>
            <Divider />

            {notifications.length > 0 ? (
              notifications.map((notification, index) => (
                <Box key={index} sx={{ justifyContent: 'right', alignItems: 'right' }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    {notification.title}
                  </Typography>

                  <Typography
                    key={index}
                    variant="body2"
                    color="text.secondary"
                    style={{ paddingBottom: '8px' }}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {notification?.n_date}
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => handleReadNotification(notification.n_id)}
                      sx={{ marginLeft: 'auto' }}
                    >
                      <Iconify icon="solar:check-read-line-duotone" width={24} />
                    </Button>
                  </Typography>

                  <Divider />
                </Box>
              ))
            ) : (
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                No Notification
              </Typography>
            )}
          </Box>
          {notifications.length > 0 ? (
            <LoadingButton
              loading={readLoading}
              variant="outlined"
              color="primary"
              onClick={handleReadAllNotification}
              sx={{ mt: 0 }}
            >
              Mark All as Read
            </LoadingButton>
          ) : null}
        </Stack>
      </CustomPopover>
    </>
  );
}
