import Request from '.';
import { ApiRoutes } from '../constants';

export const insertLoanApi = async (formdata) => {
  try {
    const res = await Request.post(ApiRoutes.INSERTLOAN, formdata);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateLoanApi = async (formdata) => {
  try {
    const res = await Request.post(ApiRoutes.UPDATELOAN, formdata);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getLoanApi = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETLOAN, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteLoanApi = async (formdata) => {
  try {
    const res = await Request.get(ApiRoutes.DEKETELOAN + formdata);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getDealerListApi = async () => {
  try {
    const res = await Request.get(ApiRoutes.GETDEALERLIST);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteRtoAssetsApi = async (data) => {
  try {
    const res = await Request.get(`${ApiRoutes.DELETERTOASSETS}/${data}`);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getPayoutTotalApi = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETPAYOUTTOTAL, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getLoanDetailsApi = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.GETLOANDETAILSDATA, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateLoanStatusApi = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.UPDATELOANSTATUS, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const downloadLoanReportApi = async (data) => {
  try {
    const res = await Request.post(ApiRoutes.DOWNLOADDEALERWISELOANREPORT, data);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
